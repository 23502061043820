<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="params.name" />
        </el-form-item>
        <el-form-item label="分组ID" prop="middlemanId">
          <el-input v-model="params.middlemanId" />
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="middlemanId"
          label="分组ID"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="分组名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="relateStoreCount"
          label="关联门店数"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="已调价商品件数">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="showDetail(row.id)">{{
              row.priceCount
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="修改时间"
        >
          <template slot-scope="{ row }">
            <span>{{ row.createTime | date }}</span>
          </template>
        </el-table-column>

        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <el-button @click="editAmount(row)" type="text"
              >设置商品价格</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      @getData="getData(true)"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />

    <AmountModal
      v-if="amountVisible"
      @getData="getData(true)"
      :visible.sync="amountVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal'
import AmountModal from './components/AmountModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: null,
      detailVisible: false,
      amountVisible: false,
      tableData: [
        {
          id: 1,
          relateStoreCount: 11,
          priceCount: 11
        }
      ],
      typeList: [
        {
          label: '中间商',
          value: 0
        },
        {
          label: '门店',
          value: 1
        }
      ],
      params: {
        name: '',
        customerType: '',
        middlemanId: '',
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    DetailModal,
    AmountModal
  },
  created() {
    // this.getData()
  },
  methods: {
    addStore() {
      this.currentItem = null
      this.addVisible = true
    },
    showDetail(row) {
      this.currentItem = row
      this.detailVisible = true
    },
    editAmount(row) {
      this.currentItem = row
      this.amountVisible = true
    },
    async getData(query) {
      // try {
      //   this.loading = true
      //   let params = { ...this.params }
      //   if (query) {
      //     params.limit = 20
      //     params.page = 1
      //   }
      //   const res = await this.$api.groupConfig.getList(params)
      //   if (res.page && res.page.list) {
      //     this.tableData = res.page.list.map(item => {
      //       item.discountWay = Number(item.discountWay)
      //       return item
      //     })
      //   }
      //   this.totalCount = res.page.totalCount
      // } catch (err) {
      // } finally {
      //   this.loading = false
      // }
    }
  }
}
</script>

<style scoped lang="scss"></style>
