<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="修改分组采购价"
    :visible.sync="show"
    width="550px"
  >
    <el-form
      @submit.native.prevent
      size="small"
      :model="form"
      ref="form"
      label-width="100px"
    >
      <el-form-item label="市场价">
        <span>{{ Number(supplyPrice) || 0 }}</span>
      </el-form-item>

      <el-form-item label="分组采购价" prop="discountSupplyPrice">
        <el-input
          type="number"
          @blur="blurHandler(form.discountSupplyPrice)"
          v-model="form.discountSupplyPrice"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['supplyPrice', 'currentId'],
  data() {
    return {
      loading: false,
      form: {
        discountSupplyPrice: 0
      }
    }
  },

  methods: {
    blurHandler(val) {
      if (val > Number(this.supplyPrice)) {
        this.form.discountSupplyPrice = this.supplyPrice
      }
    },
    async formSubmit() {
      this.loading = true

      try {
        await this.$api.groupConfig.priceSave({
          discountSupplyPrice: this.form.discountSupplyPrice,
          goodsId: this.currentId,
          middlemanId: this.currentItem.middlemanId,
          customerType: this.currentItem.customerType
        })

        this.$message.success('修改成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
