<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="已调价商品明细"
    :visible.sync="show"
    width="700px"
  >
    <div>
      <el-table :data="tableData" v-loading="loading" :max-height="400" border>
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column prop="goodsImg" label="商品图片">
          <template #default="{ row }">
            <el-popover
              v-if="row.goodsImg"
              effect="light"
              trigger="hover"
              placement="top"
              width="320px"
            >
              <template #default>
                <el-image
                  style="width: 300px; height: 300px"
                  :src="row.goodsImg"
                  fit="scale-down"
                ></el-image>
              </template>
              <template #reference>
                <el-image
                  style="height: 50px"
                  :src="row.goodsImg"
                  fit="scale-down"
                  :preview-src-list="[row.goodsImg]"
                ></el-image>
              </template>
            </el-popover>

            <el-image
              v-else
              :src="row.goodsImg"
              class="table-image"
              style="width: 50px; height: 50px"
            >
              <div slot="error" class="image-slot">
                <i
                  style="font-size: 50px !important"
                  class="el-icon-picture-outline"
                ></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="条形码"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="supplyPrice"
          label="市场价"
        >
        </el-table-column>
        <el-table-column prop="groupPrice" label="分组采购价"></el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false"> 关闭 </el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      totalCount: 0,
      tableData: [],
      params: {
        limit: 20,
        page: 1
      }
    }
  },
  async created() {
    // this.getData()
  },

  methods: {
    async getData() {
      this.loading = true

      const sendData = { ...this.params }
      sendData.id = this.currentItem.id
      try {
        const res = await this.$api.groupConfig.detail(sendData)

        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(_ => {
            _.goodsImg = this.$utils.getImg(_.goodsImg)

            return _
          })
        }

        this.totalCount = res.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tips {
  color: brown;
  margin-left: 33px;
}
</style>
