<template>
  <el-dialog
    class="AmountModal"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="设置商品价格"
    :visible.sync="show"
    width="1200px"
  >
    <div class="formWrap">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input placeholder="商品名称" v-model="params.goodsName" />
        </el-form-item>
        <el-form-item label="条形码" prop="barNo">
          <el-input placeholder="条形码" v-model="params.barNo" />
        </el-form-item>
        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select v-model="params.clazzId1" clearable>
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌" prop="goodsBrand">
          <el-select v-model="params.goodsBrand" clearable filterable>
            <el-option
              v-for="item in brandList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" plain @click="getData(true)">
          查询
        </el-button>
        <el-button plain @click="parentClear">重置</el-button>
      </el-form>
    </div>

    <div>
      <el-table :data="tableData" v-loading="loading" :height="400" border>
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column prop="goodsImg" label="商品图片">
          <template #default="{ row }">
            <el-popover
              v-if="row.goodsImg"
              effect="light"
              trigger="hover"
              placement="top"
              width="320px"
            >
              <template #default>
                <el-image
                  style="width: 300px; height: 300px"
                  :src="row.goodsImg"
                  fit="scale-down"
                ></el-image>
              </template>
              <template #reference>
                <el-image
                  style="height: 50px"
                  :src="row.goodsImg"
                  fit="scale-down"
                  :preview-src-list="[row.goodsImg]"
                ></el-image>
              </template>
            </el-popover>

            <el-image
              v-else
              :src="row.goodsImg"
              class="table-image"
              style="width: 50px; height: 50px"
            >
              <div slot="error" class="image-slot">
                <i
                  style="font-size: 50px !important"
                  class="el-icon-picture-outline"
                ></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="条形码"
        ></el-table-column>
        <el-table-column
          prop="clazzName1"
          label="商品一级分类"
          min-width="100"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="brandName" label="品牌" />

        <el-table-column
          show-overflow-tooltip
          prop="supplyPrice"
          label="市场价"
        >
        </el-table-column>

        <el-table-column
          prop="discountSupplyPrice"
          label="分组采购价"
        ></el-table-column>

        <el-table-column width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button @click="editPriceHandler(row)" size="mini" type="text">
              修改分组采购价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>

    <EditPriceModal
      v-if="editPriceVisible"
      :visible.sync="editPriceVisible"
      :supplyPrice="supplyPrice"
      :currentId="currentId"
      :currentItem="currentItem"
      @getData="getData(true)"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import EditPriceModal from './EditPriceModal'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      editPriceVisible: false,
      supplyPrice: 0,
      currentId: null,
      tableData: [],
      typeList1: [],
      brandList: [],
      totalCount: 0,
      params: {
        limit: 20,
        page: 1
      }
    }
  },
  components: {
    EditPriceModal
  },
  created() {
    this.getBrandList()
    this.getTypeList()
    this.getData()
  },
  methods: {
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },
    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getTypeList()
        // this.typeList1 = (res.list || []).map(item => {
        //   return {
        //     label: item.clazzName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })
        this.brandList = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getBrandList()
        // this.brandList = (res.list || []).map(item => {
        //   return {
        //     label: item.brandName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    editPriceHandler(row) {
      this.supplyPrice = row.supplyPrice
      this.currentId = row.id
      this.editPriceVisible = true
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        params.middlemanId = this.currentItem.middlemanId
        params.customerType = this.currentItem.customerType

        const res = await this.$api.groupConfig.getProductList(params)

        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(_ => {
            _.goodsImg = this.$utils.getImg(_.goodsImg)

            _.supplyPrice = _.supplyPrice || '-'
            _.discountSupplyPrice = _.discountSupplyPrice || '-'

            return _
          })
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.AmountModal .el-dialog__body {
  padding: 0 20px !important;
}

.AmountModal .el-dialog {
  margin-top: 5vh !important;
}

.AmountModal .el-dialog .el-form-item {
  margin-bottom: 5px !important;
}

.pagination-wrap {
  justify-content: center !important;
}

.formWrap {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
}
</style>
